<template>
  <div class="page-404">
    <div class="main-box">
      <div class="left-box">
        <div class="title">{{ ERROR_PAGE_TRANSLATE?.title }}</div>
        <Button @click="toMain">{{ ERROR_PAGE_TRANSLATE?.btn }}</Button>
      </div>
      <div class="right-box" v-bind:style="{ 'background-image': 'url(' + ERROR_PAGE_TRANSLATE?.image + ')' }">
        <img :src="ERROR_PAGE_TRANSLATE?.image" alt="bg-404" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/parts/button.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Button
  },
  computed: {
    ...mapGetters(["USER", "ERROR_PAGE_TRANSLATE"])
  },
  methods: {
    ...mapActions(["AboutMe", "ErrorPageTranslate"]),
    toMain() {
      this.$router.push({ name: "Main" });
    }
  },
  created() {
    let token = localStorage.getItem("token");
    this.ErrorPageTranslate();
  }
};
</script>

<style lang="scss">
.page-404 {
  header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  .main-box {
    background-color: var(--bg-main);
    height: 100vh;
    width: 100%;
    display: flex;
  }
  .left-box {
    padding-left: 20px;
    padding-right: 20px;
    align-items: flex-start;
    min-width: 710px;
    height: 100%;
    width: 50%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .title {
      margin-bottom: 36px;
      max-width: 600px;
      font-family: Aeonik Pro;
      font-size: 62px;
      line-height: 70px;
      letter-spacing: 0.01em;
      color: var(--dark-100);
    }
    button.m-text-des {
      min-height: 48px;
      text-transform: uppercase;
      span {
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.04em;
      }
    }
  }
  .right-box {
    margin-top: 250px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    img {
      display: none;
    }
  }
  @media (max-width: 764px) {
    .main-box {
      justify-content: space-between;
      position: relative;
      flex-direction: column;
      height: 100%;
      min-height: 100vh;
    }
    .left-box {
      height: 50%;
      height: auto;
      width: 100%;
      min-width: auto;
      padding-bottom: 56px;
      justify-content: flex-start;

      .title {
        max-width: 370px;
        padding-top: 175px;
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 40px;
      }
    }
    .right-box {
      margin-top: 0;
      position: relative;
      background: none;
      margin-left: 0;
      width: 100%;
      height: 200px;
      overflow: hidden;
      flex-shrink: 0;
      img {
        top: 150%;
        right: 0%;
        height: 100vw;
        position: absolute;
        display: flex;
        transform: rotate(90deg) scale(1.1);
        transform-origin: 100% 0;
        object-fit: cover;
      }
    }
  }
}
</style>
